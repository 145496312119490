.space-1 {
    margin-bottom: 1rem;
}

.space-2 {
    margin-bottom: 2rem;
}

.space-3 {
    margin-bottom: 3rem;
}

.color-red {
    color: $hawa-red;
}
$hawa-dark-blue: rgb(0, 60, 120);
$hawa-light-blue: rgb(8, 156, 200);
$hawa-red: rgb(230, 0, 10);
$hawa-light-gray: rgb(233, 234, 235);

$gray: rgb(187, 187, 187);

$error: $hawa-red;

$mobile-header-height: 60px;
$main-header-height: 134px;
$top-bar-height: 36px;
$footer-height: 106px;

$input-font: $body-font-family;
$input-error-color: $hawa-red;
$input-border-color: rgb(202, 202, 202);
$input-label-color: $hawa-dark-blue;

$select-label-color: $hawa-dark-blue;
$select-font: $body-font-family;
$select-border-color: rgb(202, 202, 202);
$select-error-color: $hawa-red;

$hawa-link-hover: $hawa-red;
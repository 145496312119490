.top-header {
    background-color: $hawa-light-gray;

    .wrapper {
        display: flex;
        margin: 0 auto;
        padding-left: (map-get($grid-margin-gutters, medium) / 2);
        padding-right: (map-get($grid-margin-gutters, medium) / 2);
        height: 100%;
        max-width: $global-width;
        align-items: center;
        justify-content: flex-end;
    }
}
body {
    overflow-x: hidden;
    background-color: $light-gray;
}

a {
    color: $hawa-light-blue;
    text-decoration: none;
    transition: color .1s ease-in-out;

    &:hover {
        color: $hawa-red;
    }
}

.back-link {
    display: inline-block;
    padding: rem-calc(4) 0;
    margin-bottom: rem-calc(4);
}

.f-weight-normal {
    font-weight: normal;
}

p {
    line-height: 1.25;
}

h1 {
    margin: 0 0 1.8em;
    font-size: rem-calc(24);
    color: $hawa-dark-blue;

    @include breakpoint(medium) {
        font-size: rem-calc(40);
    }
}
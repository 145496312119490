.main-header {
    position: relative;
    height: rem-calc(60);
    border-bottom: 1px solid $hawa-light-gray;
    background-color: $white;

    .wrapper {
        display: flex;
        margin: 0 auto;
        height: 100%;
        max-width: $global-width;
        align-items: center;
        padding-left: (map-get($grid-margin-gutters, small) / 2);

        @include breakpoint(large) {
            padding-left: (map-get($grid-margin-gutters, medium) / 2);
            padding-right: (map-get($grid-margin-gutters, medium) / 2);
        }
    }

    .logo-container {
        flex: 1 0 auto;
    }

    .menu-container {
        display: none;
        position: absolute;
        top: $mobile-header-height;
        left: 0;
        width: 100%;
        z-index: 5;

        &.is-open {
            display: block;
        }

        @include breakpoint(large) {
            position: relative;
            display: block;
            width: auto;
            top: auto;
            left: auto;
            align-self: flex-end;
            padding-bottom: rem-calc(40);
        }
    }

    @include breakpoint(large) {
        height: rem-calc($main-header-height);
    }

    .logo {
        display: inline-block;
        height: rem-calc(48);

        img {
            max-width: none;
            height: 100%;
            width: auto;
        }

        @include breakpoint(large) {
            height: auto;
            width: rem-calc(150);
        }
    }

    .burger {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem-calc(60);
        width: rem-calc(60);
        border-left: 1px solid $hawa-light-gray;
        cursor: pointer;

        rect {
            transition: fill .2s ease-in-out;
        }

        &.is-open {
            rect {
                fill: $hawa-red;
            }
        }

        @include breakpoint(large) {
            display: none;
        }
    }
}